(function($){
  /**
   *
   *
   */
  $('.simulator-popover').each(function(index) {
    var $element  = $(this);
    var message   = $element.attr('data-popover-message');
    var isVisible = $element.attr('data-popover-visible');
    var $popover  = $('<div/>');

    $popover.text(message);
    $popover.addClass('popover-message');

    if(isVisible) {
      $popover.addClass('visible');
    }

    $element.after($popover);

    $element.parent().hover(
      function() {
        $popover.addClass('visible');
      },
      function() {
        $popover.removeClass('visible');
      }
    );
  });
})(jQuery);
